<template>
  <b-card-code no-body>
    <b-row>
      <div class="col-6 d-flex justify-content-start">
        <h3 class="text-primary ml-2">Domain Discovered</h3>
      </div>
      <div class="col-6 d-flex justify-content-end">
        <router-link
          class="text-white"
          :to="{ path: '/dashboard', query: { tabIndex: 2 } }"
        >
          <button
            type="button"
            class="btn btn-primary btn-sm mr-1"
            style="position: relative"
          >
            Back
          </button>
        </router-link>
      </div>
    </b-row>
    <b-row>
      <div class="col-12 d-flex justify-content-end my-1 px-2">
        <b-form-input
          class="mr-1 w-50"
          id="domainname"
          v-model="domain_filter"
          name="name"
          placeholder="Search with Domain"
        />
        <b-form-input
          class="mr-1 w-50"
          id="ipname"
          v-model="ip_filter"
          name="name"
          placeholder="Search with IP"
        />
        <button
          type="button"
          class="btn btn-secondary btn-sm mr-1"
          style="position: relative"
          @click="resetFn()"
        >
          Reset
        </button>
        <button
          type="button"
          class="btn btn-primary btn-sm"
          style="position: relative"
          @click="load()"
        >
          Search
        </button>
      </div>
    </b-row>
    <!-- {{items}} -->
    <div>
      <b-table responsive id="blTable" :fields="fields" :items="items">
        <template #cell(asset)="data">
          {{ data.item.asset }} <br />
          <div v-if="data.item.is_newly_founded && data.item.domain != null">
            <button
              @click="addToAssets(data.item.id, 'domain', data.index)"
              type="button"
              class="btn btn-outline-warning btn-sm mr-1"
              style="position: relative"
              :disabled="isDisable"
            >
              <span v-if="isLoading && data.index === currentIndexDomain"
                ><b-spinner type="border" small />Please wait</span
              >
              <span v-else>Add to assets</span>
            </button>
            <button
              type="button"
              class="btn btn-outline-warning btn-sm mr-1"
              @click="addAndScan(data.item.id, 'domain')"
              :disabled="isDisable"
            >
              <span v-if="isLoading && data.index === currentIndexDomain"
                ><b-spinner type="border" small />Please wait</span
              >
              <span v-else> Add and Scan</span>
            </button>
          </div>
          <div
            v-else-if="
              !data.item.domain_added_to_asset && data.item.domain != null
            "
          >
            <button
              @click="addToAssets(data.item.id, 'domain', data.index)"
              type="button"
              class="btn btn-outline-primary btn-sm mr-1"
              style="position: relative"
              :disabled="isDisable"
            >
              <span v-if="isLoading && data.index === currentIndexDomain"
                ><b-spinner type="border" small />Please wait</span
              >
              <span v-else>Add to assets</span>
            </button>
            <button
              type="button"
              class="btn btn-outline-primary btn-sm mr-1"
              @click="addAndScan(data.item.id, 'domain')"
              :disabled="isDisable"
            >
              <span v-if="isLoading && data.index === currentIndexDomain"
                ><b-spinner type="border" small />Please wait</span
              >
              <span v-else> Add and Scan</span>
            </button>
          </div>
          <div
            v-else-if="
              data.item.domain_added_to_asset && data.item.domain != null
            "
          >
            <button
              type="button"
              class="btn btn-outline-success btn-sm mr-1"
              style="
                position: relative;
                padding-left: 35px;
                padding-right: 35px;
              "
              :disabled="!data.item.domain_ref_asset_id"
              @click="
                $router.push({
                  path: '/assets/scan/' + data.item.domain_ref_asset_id,
                })
              "
            >
              Added
            </button>
          </div>
        </template>
        <template #cell(ip_address)="data">
          {{ data.item.ip_address }} <br />
          <div
            v-if="data.item.is_newly_founded && data.item.ip_address != null"
          >
            <button
              @click="addToAssets(data.item.id, 'ip', data.index)"
              type="button"
              class="btn btn-outline-warning btn-sm mr-1"
              style="position: relative"
              :disabled="isDisableIP"
            >
              <span v-if="isLoadingIP && data.index === currentIndexIP"
                ><b-spinner type="border" small />Please wait</span
              >
              <span v-else>Add to assets</span>
            </button>
            <button
              type="button"
              class="btn btn-outline-warning btn-sm mr-1"
              @click="addAndScan(data.item.id, 'ip')"
              :disabled="isDisableIP"
            >
              <span v-if="isDisableIP && data.index === currentIndexIP"
                ><b-spinner type="border" small />Please wait</span
              >
              <span v-else> Add and Scan</span>
            </button>
          </div>
          <div
            v-else-if="
              !data.item.ip_added_to_asset && data.item.ip_address != null
            "
          >
            <button
              @click="addToAssets(data.item.id, 'ip', data.index)"
              type="button"
              class="btn btn-outline-primary btn-sm mr-1"
              style="position: relative"
              :disabled="isDisableIP"
            >
              <span v-if="isLoadingIP && data.index === currentIndexIP"
                ><b-spinner type="border" small />Please wait</span
              >
              <span v-else>Add to assets</span>
            </button>
            <button
              type="button"
              class="btn btn-outline-primary btn-sm mr-1"
              @click="addAndScan(data.item.id, 'ip')"
              :disabled="isDisableIP"
            >
              <span v-if="isDisableIP && data.index === currentIndexIP"
                ><b-spinner type="border" small />Please wait</span
              >
              <span v-else> Add and Scan</span>
            </button>
          </div>
          <div
            v-else-if="
              data.item.ip_added_to_asset && data.item.ip_address != null
            "
          >
            <button
              type="button"
              class="btn btn-outline-success btn-sm mr-1"
              style="
                position: relative;
                padding-left: 35px;
                padding-right: 35px;
              "
              :disabled="!data.item.ip_ref_asset_id"
              @click="
                $router.push({
                  path: '/assets/scan/' + data.item.ip_ref_asset_id,
                })
              "
            >
              Added
            </button>
          </div>
        </template>
      </b-table>
      <div class="mt-1">
        <b-pagination-nav
          v-model="currentPage"
          :numberOfPages="total"
          :total-rows="rows"
          :link-gen="linkGen"
          :per-page="perPage"
          :align="pagination_pos"
          aria-controls="blTable"
          use-router
        />
      </div>
    </div>
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTable,
  BRow,
  BPaginationNav,
  BSpinner,
  BFormInput,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BCardCode,
    BTable,
    BRow,
    BPaginationNav,
    BSpinner,
    BFormInput,
  },
  //   props: {
  //     org_id: {
  //       type: Number,
  //       required: true,
  //     },
  //   },
  data() {
    return {
      fields: [
        { key: "domain", label: "Domain" },
        { key: "asset", label: "Domain Discovered" },
        { key: "ip_address", label: "IP" },
      ],
      items: [],
      // organization: !this.$store.state.app.user.is_staff
      //   ? this.$store.state.app.user.profile.organization
      //   : this.$store.state.app.org_id,
      organization: this.$store.state.app.org_id,
      pagination_pos: "center",
      currentPage: 1,
      perPage: 10,
      rows: 0,
      total: 1,
      isLoading: false,
      isDisable: false,
      isLoadingIP: false,
      isDisableIP: false,
      type: "",
      currentIndexDomain: Number,
      currentIndexIP: Number,
      domain_filter: "",
      ip_filter: "",
    };
  },
  watch: {
    currentPage: function (newVal, oldVal) {
      this.load(false);
    },
  },
  created: function () {
    if (this.organization != null) {
      this.load();
    }
  },
  methods: {
    linkGen: function (pageNum) {
      return this.$router.currentRoute.path + "?page=" + pageNum;
    },
    load: function (reset = true) {
      if (this.$route.query.page) this.currentPage = this.$route.query.page;
      if (reset) this.currentPage = 1;
      let url =
        process.env.VUE_APP_BASEURL +
        "dashboard/v1/domains-discovered?query=true" +
        "&page=" +
        this.currentPage +
        "&org_id=" +
        this.organization;

      if (this.domain_filter != "") {
        url = url + "&domain=" + this.domain_filter;
      }
      if (this.ip_filter != "") {
        url = url + "&ip_address=" + this.ip_filter;
      }
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        console.log(res.data.results);
        // this.toastMessage(res)  // method call to method2
        this.items = res.data.results;
        this.rows = res.data.results.length;
        this.total = Math.ceil(res.data.count / this.perPage);
      });
    },
    resetFn() {
      this.domain_filter = "";
      this.ip_filter = "";
      this.load();
    },
    addToAssets: function (id, type, index) {
      this.type = type;
      this.currentIndexDomain = index;
      this.currentIndexIP = index;
      if (this.type === "domain") {
        this.isDisable = true;
        this.isLoading = true;
      } else {
        this.isDisableIP = true;
        this.isLoadingIP = true;
      }
      let data = {
        type: type,
      };
      // add-to-asset
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset-discovery/discovered-assets/" +
          id +
          "/add-to-asset/",
      };
      var self = this;
      this.$http(options).then((res) => {
        if (res.data) {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "TrashIcon",
              variant: "success",
            },
          });
          this.load();
        } else {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "TrashIcon",
              variant: "danger",
            },
          });
        }
        this.isDisable = false;
        this.isLoading = false;
        this.isDisableIP = false;
        this.isLoadingIP = false;
      });
    },
    addAndScan: function (id, type, index) {
      this.type = type;
      this.currentIndexDomain = index;
      this.currentIndexIP = index;
      if (this.type === "domain") {
        this.isDisable = true;
        this.isLoading = true;
      } else {
        this.isDisableIP = true;
        this.isLoadingIP = true;
      }
      let data = {
        type: type,
      };
      // add-to-asset
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset-discovery/discovered-assets/" +
          id +
          "/add-asset-and-scan/",
      };
      var self = this;
      this.$http(options).then((res) => {
        if (res.data) {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "TrashIcon",
              variant: "success",
            },
          });
          this.loadAssets();
        } else {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "TrashIcon",
              variant: "danger",
            },
          });
        }
        this.isDisable = false;
        this.isLoading = false;
        this.isDisableIP = false;
        this.isLoadingIP = false;
      });
    },
  },
};
</script>